<template>
  <div>
    <form>
      <b-card>
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col md="6" class="mb-2">
              <h5 class="mb-0">Basic Details</h5>
              <small class="text-muted">
                Enter User Basic Details.
              </small>
            </b-col>
            <b-col md="6">
              <div class="demo-inline-spacing">
                <b-form-checkbox v-model="data_local.status" switch inline>
                  Status
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <label class="required-l" for="name">Name</label>
                  <b-form-input
                    id="name"
                    v-model="data_local.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="User Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <label class="required-l" for="email">Email</label>
                  <b-form-input
                    id="email"
                    v-model="data_local.email"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="user@mail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="user id"
                  rules="required"
                >
                  <label class="required-l" for="user-id"
                    >User Id</label
                  >
                  <b-form-input
                    id="user-id"
                    v-model="data_local.employee_id"
                    :state="errors.length > 0 ? false : null"
                    placeholder="User Id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            
            <b-col md="6">
              <b-form-group>
                <label class="required-l" for="roles">Roles</label>
                <v-select
                  v-model="data_local.role_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="list_roles"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="role"
                  placeholder="Select Role.."
                />
              </b-form-group>
            </b-col>
            
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="formSubmitted"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </form>

  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import userStoreModule from "../userStoreModule";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      required,
      email,
      numeric,
      reportingManagerOptions: [],
      data_local: {
        name: "",
        email: "",
        status: null,
        employee_id: "",
        role_id: "",
      },
      list_roles: [],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-users";
   
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },
  
  created() {
    this.fetch_user_data(this.$route.params.id);
    this.fetchRolesList();
  },
  methods: {
    
    resetModal() {
      this.designationName = "";
    },
    fetchRolesList() {
      this.isloading = true;
      this.$store
        .dispatch("app-users/fetchRolesList")
        .then((response) => {
          this.list_roles = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    fetch_user_data(userId) {
      this.$store
        .dispatch("app-users/fetchUser", userId)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    formSubmitted() {
      this.$store
        .dispatch("app-users/updateUser", {
          data: this.data_local,
          userId: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "User Updated",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'User Updated',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            // if ($last_step) {
            //   this.$router.push("/users");
            // }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! User updating Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! User updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! User Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Ticket Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        })
        .finally(() => (this.isloading = false));
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
            this.formSubmitted();
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
            this.formSubmitted();
          } else {
            reject();
          }
        });
      });
    },
    fetchData() {
      this.$http
        .get("/checklistform", { params: { q: this.faqSearchQuery } })
        .then((res) => {
          this.faqData = res.data.data;
        });
      this.$http.get("/list/users").then((res) => {
        this.reportingManagerOptions = res.data.data;
      });
    },
    remove(index) {
      this.position_history.splice(index, 1);
    },
    addPositionHistory() {
      if (!this.position_history) {
        this.position_history = [
          {
            designation_id: "",
            designation: "",
            effective_from: "",
            effective_to: "",
          },
        ];
      } else {
        this.position_history.push({
          designation_id: "",
          designation: "",
          effective_from: "",
          effective_to: "",
        });
      }
      console.log(this.position_history);
    },
    removePreviousUserment(index) {
      this.previous_userments.splice(index, 1);
    },
    addPreviousEmployment() {
      if (!this.previous_userments) {
        this.previous_userments = [
          {
            designation_id: "",
            designation: "",
            effective_from: "",
            effective_to: "",
            company_name: "",
            experience: 0,
            leaving_reason: "",
            company_address: "",
            pf_number: "",
          },
        ];
      } else {
        this.previous_userments.push({
          designation_id: "",
          designation: "",
          effective_from: "",
          effective_to: "",
          company_name: "",
          experience: 0,
          leaving_reason: "",
          company_address: "",
          pf_number: "",
        });
      }
      console.log(this.previous_userments);
    },
    removeDocument(index) {
      this.documents.splice(index, 1);
    },
    addDocument() {
      if (!this.documents) {
        this.documents = [
          {
            document_name: "",
            description: "",
            file: null,
          },
        ];
      } else {
        this.documents.push({
          document_name: "",
          description: "",
          file: null,
        });
      }
      console.log(this.documents);
    },

    openWindow(link) {
      window.open(link);
    },
    documentSubmitted() {
      const self = this;

      const formData = new FormData(document.getElementById("document-form"));

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      if (self.$refs.pond && self.$refs.pond.getFiles()) {
        const files = self.$refs.pond.getFiles();
        const keys = Object.keys(self.$refs.pond.getFiles());
        //check when save and assign interview btn clicked
        if (keys.length == 0) {
          return;
        }
        for (let i = 0; i < keys.length; i += 1) {
          formData.append(`files[${i}]`, files[i].file);
        }
      }

      formData.append("userId", self.data_local.hashid);
      formData.append("document_name", self.document.document_name);
      formData.append("description", self.document.description);
      if (self.document.hash) {
        formData.append("hash", self.document.hash);
      }
      if (self.document.file) {
        formData.append("file", self.document.file);
      }
      let data = {};
      data.url = "upload-document";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.isBusy = false;
          $("#document-cancel").click();
          $("#document-form").trigger("reset");
          this.documents = res.data.data;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Document Upload",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Document Upload',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Document UploadFailed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Document UploadFailed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    editDocument(k) {
      $("#add-document").click();
      this.document = this.documents[k];
    },
    removeProfileAvatar() {
      let data = {};
      data.url = "/user/update-profile";
      data.params = {
        "remove-avatar": true,
        userId: this.$route.params.id,
      };

      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Profile Image removed",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Profile Image removing Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Profile Image removing Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    updateProfilePic() {
      const self = this;
      const formData = new FormData();

      formData.delete("avatar"); // data is the name of the original input file; import to delete

      if (this.selectedFile) {
        formData.append(`avatar`, this.selectedFile, "blobs.jpg");
      }

      formData.append("userId", self.data_local.hashid);

      self.$http
        .post("/user/update-profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status == "200") {
            if (self.imageSrc) {
              self.data_local.avatar = self.imageSrc;
            }
            this.fileCleared();
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Profile Update success.",
            //     icon: "BellIcon",
            //     text: res.data.message,
            //     variant: "success",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Update success',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Profile Update Failed!",
            //     icon: "BellIcon",
            //     text: res.data.message,
            //     variant: "danger",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Profile Update Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });

                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Update Failed"',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Profile Update Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Profile Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-faq.scss";

@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "~cropperjs/dist/cropper.css";

.filepond--credits {
  display: none;
}
.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}

.filepond--credits {
  display: none;
}
.close-crop-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.btn-crop-container {
  display: flex;
  justify-content: center;
  align-content: flex-end;
}
</style>

